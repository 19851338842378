<template>
  <section>
    <PanelHeaderEquipmentList
      :title="title"
      v-bind:nav="nav"
      icon="fa fa-dashboard"
      :connector_id="connector_id"
      ref="header"
    />
    <div class="panel-content">
      <h1 class="dashboard-title">
        {{ $t("page.history_usage_statistics") }}
      </h1> 
      <ConnectorHistoryUsageStatistics
        :connector_id="connector_id"
      />
    </div>
  </section>
</template>

<script>
import PanelHeaderEquipmentList from "@/components/panel-header-equipment-list.vue";
import ConnectorHistoryUsageStatistics from "@/components/connector-history-usage-statistics.vue";

export default {
  name: "DashboardConnectorHistoryUsageStatistics",
  components: {
    PanelHeaderEquipmentList,
    ConnectorHistoryUsageStatistics
  },
  props: {
    connector_id: {
      type: Number,
      required: true
    },

  },
  data() {
    return {
      argumentsnav: null
    }
  },
  computed: {
    title() {
      return `${this.$tc("connector",1)}: ${this.connector?.name ?? ""}`;
    },
    parsedNav() {
      return {}
    },
    connector() {
      return this?.parent || (this.$store.getters["dashboard/connectorList"] || []).find(
        ({ id }) => id == this.connector_id
      );
    },
    nav() {
      return {
        previous: `/dashboard/edit/connector/`,
        items: [
          {
            name: "connector_plural",
            url: "/dashboard/edit/connector"
          },
          {
            name: this.connector?.name,
            url: `/dashboard/edit/connector/${this.connector_id}`
          },
          {
            name: this.$t("page.history_usage_statistics"),
            url: `/dashboard/edit/connector/${this.connector_id}/data_history_usage`
          }
        ]
      }
    }
  },
  mounted() {
    this.$emit("loading", false);
  }
};
</script>

<style scoped>
.panel-content {
  padding: 0px 10px 10px 10px;
}
h1.dashboard-title {
  font-size: 14pt;
  font-weight: 700;
  color: #777;
  text-align: left;
  padding-left: 0.8em;
  padding: 0 0 5px 0;
  margin-bottom: 5px;
  border-bottom: 1px solid #cdcdcd;
}
</style>
