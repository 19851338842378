<template>
  <div
    class="modal fade"
    tabindex="-1"
    role="dialog"
    :data-backdrop="backdrop"
    :data-keyboard="!busy"
  >
    <div
      :class="[
        'modal-dialog',
        { 'fit-content marginModal': fitContent, 'modal-lg': 'large' }
      ]"
      role="document"
      data-testid="dialog"
    >
      <div class="modal-content">
        <div class="modal-header">
          <slot name="header">
            <BaseButton
              type="close"
              data-dismiss="modal"
              aria-label="Close"
              v-if="canClose"
            >
              <span aria-hidden="true">&times;</span>
            </BaseButton>
            <h4 class="modal-title" :class="{'text-center': centerTitle}" data-testid="title">
              <i v-if="iconClass" :class="iconClass"></i>
              {{ title }}
            </h4>
          </slot>
        </div>
        <div
          v-if="content"
          class="modal-body"
          :class="modalBodyClass"
          data-testid="content"
          v-html="content"
        ></div>
        <div v-else class="modal-body" data-testid="content">
          <slot name="content"></slot>
        </div>
        <div class="modal-footer" v-if="footer">
          <slot name="footer">
            <BaseButton
              class="pull-left"
              data-dismiss="modal"
              data-testid="close"
              v-if="!busy"
            >
              {{ closeText }}
            </BaseButton>
            <BaseButton
              class="pull-right"
              type="primary"
              @click="$emit('click:ok')"
              data-testid="ok"
            >
              {{ okText }}
            </BaseButton>
          </slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseButton from "@/components/base/buttons/base-button";

export default {
  name: "Modal",
  components: { BaseButton },
  props: {
    title: {
      type: String,
      required: false,
      default: "Modal title"
    },
    centerTitle: {
      type: Boolean,
      default: false
    },
    iconClass: {
      type: String,
      required: false
    },
    content: {
      type: String,
      required: false
    },
    closeText: {
      type: String,
      required: false,
      default() {
        return this.$t("close");
      }
    },
    okText: {
      type: String,
      required: false,
      default() {
        return this.$t("ok");
      }
    },
    trigger: [HTMLElement, String, Array],
    fitContent: {
      type: Boolean,
      required: false,
      default: false
    },
    allowOverflow: {
      type: Boolean,
      required: false,
      default: false
    },
    open: {
      type: Boolean,
      default: false
    },
    backdrop: {
      type: String,
      default: "true"
    },
    canClose: {
      type: Boolean,
      default: true
    },
    footer: {
      type: Boolean,
      required: false,
      default: () => true
    },
    component_busy: {
      type: Boolean,
      required: false,
      default: () => false
    }
  },
  data() {
    return {
      event: null,
      busy: false
    };
  },
  computed: {
    modalBodyClass() {
      if (!this.allowOverflow) {
        return {
          'max-height': '70dvh'
        };
      }
      return {};
    }
  },
  methods: {
    handleTriggerClick() {
      $(this.$el).modal("toggle");
    },
    onShow() {
      if (!this.open) this.$emit("update:open", true);
      this.$emit("show", this.event == "show");
    },
    onShown() {
      this.$emit("shown");
    },
    onHide() {
      if (this.open) this.$emit("update:open", false);
      this.$emit("hide", this.event == "hide");
    },
    onHidden() {
      this.$emit("hidden");
    },
    onLoaded() {
      this.$emit("loaded");
    }
  },
  watch: {
    trigger: {
      immediate: true,
      handler(val) {
        if (val) {
          $(val).off("click", this.handleTriggerClick.bind(this));
          $(val).on("click", this.handleTriggerClick.bind(this));
        }
      }
    },
    open(val) {
      if (val) {
        this.event = "show";
        $(this.$el).modal("show");
      } else {
        this.event = "hide";
        $(this.$el).modal("hide");
      }
    },
    backdrop(val) {
      if (typeof val == "undefined") return;
      let modal = $(this.$el).data("bs.modal");
      if (typeof modal == "undefined") return;
      modal.options.backdrop = val;
    },
    component_busy(n) {
      this.busy = n;
    }
  },
  mounted() {
    $(this.$el).on("show.bs.modal", this.onShow.bind(this));
    $(this.$el).on("shown.bs.modal", this.onShown.bind(this));
    $(this.$el).on("hide.bs.modal", this.onHide.bind(this));
    $(this.$el).on("hidden.bs.modal", this.onHidden.bind(this));
    $(this.$el).on("loaded.bs.modal", this.onLoaded.bind(this));
  },
  updated() {
    $(this.$el).modal({ show: false, backdrop: this.backdrop });
  },
  beforeDestroy() {
    $(this.$el).off("show.bs.modal", this.onShow.bind(this));
    $(this.$el).off("shown.bs.modal", this.onShown.bind(this));
    $(this.$el).off("hide.bs.modal", this.onHide.bind(this));
    $(this.$el).off("hidden.bs.modal", this.onHidden.bind(this));
    $(this.$el).off("loaded.bs.modal", this.onLoaded.bind(this));
  }
};
</script>

<style scoped>
.marginModal {
  margin: 20px 200px;
}
.modal-dialog.fit-content {
  width: fit-content;
}

@media (max-width: 1080px) {
  .marginModal {
    margin: 0px;
  }
  .modal-dialog.fit-content {
    width: auto;
  }
}
</style>
