<template>
  <section>
    <!-- BEGIN BUSY -->
    <div v-if="busy">
      <div class="row">
        <div class="col-md-12">
          <Spin class="spinner" />
        </div>
      </div>
    </div>
    <!-- END BUSY -->

    <!-- BEGIN EMPTY STATS -->
    <div v-if="isFreePlan || (!busy && !items.length)">
      <div class="row">
        <div class="col-md-12">
          <div class="alert alert-info">
            <h4>
              <i class="icon fa fa-info"></i>
              {{ $t("no_data") }}
            </h4>
            <p>
              {{ $t("no_data_description") }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- END EMPTY STATS -->

    <!-- BEGIN STATS -->
    <div v-if="!busy && items.length">
      <div class="row">
        <div class="col-xl-1 col-lg-1 hidden-sm hidden-xs"></div>

        <div class="col-xl-2 col-lg-2 col-sm-6 col-xs-12">
          <div
            class="box box-default"
            :title="$t('hint_contract_history_rate')"
          >
            <div class="box-header with-border">
              <h3 class="box-title box-title-min-height">
                <i class="fa fa-history"></i>
                {{ $t("contract_history_rate") }}
              </h3>
            </div>
            <div class="box-body box-body-min-height">
              <span class="box-total-text"
                ><strong>{{ contractHistoryRate }}</strong></span
              >
            </div>
          </div>
        </div>

        <div class="col-xl-2 col-lg-2 col-sm-6 col-xs-12">
          <div class="box box-default" :title="$t('hint_monthly_consumption')">
            <div class="box-header with-border">
              <h3 class="box-title box-title-min-height">
                <i class="fa fa-calendar"></i>
                {{ $t("history_monthly_consumption_allowed_connector") }}
              </h3>
            </div>
            <div class="box-body box-body-min-height">
              <span class="box-total-text"
                ><strong>{{
                  floatFormat(dataMonthlyMaximum(currentYearMonth))
                }}</strong></span
              >
            </div>
          </div>
        </div>

        <div class="col-xl-2 col-lg-2 col-sm-6 col-xs-12">
          <div class="box box-default" :title="$t('hint_daily_consumption')">
            <div class="box-header with-border">
              <h3 class="box-title box-title-min-height">
                <i class="fa fa-info-circle"></i>
                {{ $t("history_daily_consumption_allowed_connector") }}
              </h3>
            </div>
            <div class="box-body box-body-min-height">
              <span class="box-total-text"
                ><strong>{{ floatFormat(dataDailyMaximum) }}</strong></span
              >
            </div>
          </div>
        </div>

        <div class="col-xl-2 col-lg-2 col-sm-6 col-xs-12">
          <div class="box box-default" :title="$t('hint_history_data')">
            <div class="box-header with-border">
              <h3 class="box-title box-title-min-height">
                <i class="fa fa-bar-chart"></i>
                {{ $t("data_with_history") }}
              </h3>
            </div>
            <div class="box-body box-body-min-height">
              <span class="box-total-text"
                ><strong>{{ items.length }}</strong></span
              >
            </div>
          </div>
        </div>

        <div class="col-xl-2 col-lg-2 col-sm-6 col-xs-12">
          <div class="box box-default" :title="$t('hint_history_data_blocked')">
            <div class="box-header with-border">
              <h3 class="box-title box-title-min-height">
                <i class="fa fa-ban"></i>
                {{ $t("blocked_data") }}
              </h3>
            </div>
            <div class="box-body box-body-min-height">
              <span class="box-total-text"
                ><strong>{{ blockedData.length }}</strong></span
              >
            </div>
          </div>
        </div>
      </div>

      <div class="row" style="margin-top: 10px">
        <div class="col-md-12" v-if="!busy">
          <SearchableTable
            class="searchable-table"
            v-if="items"
            :items="items"
            :pagination="true"
            :searchEnabled="true"
            :maxResult="items.length"
            :fields="fields"
            :commands="commands"
            @command="onCommand"
            @select="onSelect"
          >
            <!-- begin collecting -->
            <template v-slot:collecting="entry">
              <div class="column-content">
                <template v-if="entry.item.is_blocked">
                  <span
                    class="label label-danger"
                    :title="hintBlockedAt(entry.item.blocked_at)"
                  >
                    <i class="fa fa-ban"></i>
                    <span v-if="entry.item.is_blocked">
                      {{ dateTimeFormat(entry.item.blocked_at) }}
                    </span>
                  </span>
                </template>
                <template
                  v-if="!entry.item.is_blocked && entry.item.limit_reached_3"
                >
                  <span :title="hintLimitReached(limits[2])">
                    <i class="fa fa-warning text-yellow"></i>
                  </span>
                </template>
                <template
                  v-if="
                    !entry.item.limit_reached_3 && entry.item.limit_reached_2
                  "
                >
                  <span :title="hintLimitReached(limits[1])">
                    <i class="fa fa-warning text-yellow"></i>
                  </span>
                </template>
                <template
                  v-if="
                    !entry.item.limit_reached_2 && entry.item.limit_reached_1
                  "
                >
                  <span :title="hintLimitReached(limits[0])">
                    <i class="fa fa-warning text-yellow"></i>
                  </span>
                </template>
                <template v-if="!entry.item.limit_reached_1">
                  <i class="fa fa-check text-green"></i>
                </template>
              </div>
            </template>
            <!-- end collecting -->

            <!-- begin day_3_consumption -->
            <template v-slot:day_3_consumption="entry">
              <div
                class="column-content"
                :title="hintDayConsumption(entry.item, 2)"
              >
                <template
                  v-if="
                    entry.item.day_2_stats &&
                    entry.item.day_2_stats.counter != undefined
                  "
                >
                  {{ floatFormat(entry.item.day_2_stats.counter) }}
                  <i
                    class="fa fa-warning text-yellow"
                    v-if="entry.item.day_2_stats.counter >= dataDailyMaximum"
                    :title="$t('daily_limit_reached_message')"
                  ></i>
                </template>
                <template v-else> - </template>
              </div>
            </template>
            <!-- end day_3_consumption -->

            <!-- begin day_2_consumption -->
            <template v-slot:day_2_consumption="entry">
              <div
                class="column-content"
                :title="hintDayConsumption(entry.item, 1)"
              >
                <template
                  v-if="
                    entry.item.day_1_stats &&
                    entry.item.day_1_stats.counter != undefined
                  "
                >
                  {{ floatFormat(entry.item.day_1_stats.counter) }}
                  <i
                    class="fa fa-warning text-yellow"
                    v-if="entry.item.day_1_stats.counter >= dataDailyMaximum"
                    :title="$t('daily_limit_reached_message')"
                  ></i>
                </template>
                <template v-else> - </template>
              </div>
            </template>
            <!-- end day_2_consumption -->

            <!-- begin day_1_consumption -->
            <template v-slot:day_1_consumption="entry">
              <div
                class="column-content"
                :title="hintDayConsumption(entry.item, 0)"
              >
                <template
                  v-if="
                    entry.item.day_0_stats &&
                    entry.item.day_0_stats.counter != undefined
                  "
                >
                  {{ floatFormat(entry.item.day_0_stats.counter) }}
                  <i
                    class="fa fa-warning text-yellow"
                    v-if="entry.item.day_0_stats.counter >= dataDailyMaximum"
                    :title="$t('daily_limit_reached_message')"
                  ></i>
                </template>
                <template v-else> - </template>
              </div>
            </template>
            <!-- end day_1_consumption -->

            <!-- begin usage -->
            <template v-slot:current_month_usage="entry">
              <template
                v-if="
                  haveHistory(entry.item) &&
                  entry.item.year_month_values[currentYearMonth]
                "
              >
                <div class="column-content" @click.stop>
                  <div class="clearfix">
                    <small class="pull-left"
                      >{{
                        floatFormat(
                          monthlyUsage(
                            entry.item.year_month_values[currentYearMonth],
                            currentYearMonth
                          )
                        )
                      }}%</small
                    >
                  </div>
                  <div class="progress xs lesser-margin-bottom">
                    <div
                      class="progress-bar"
                      :class="
                        progressBarClass(
                          entry.item.year_month_values[currentYearMonth],
                          currentYearMonth
                        )
                      "
                      :style="
                        progressBarStyle(
                          entry.item.year_month_values[currentYearMonth],
                          currentYearMonth
                        )
                      "
                    ></div>
                  </div>
                </div>
              </template>
              <template v-else>
                <i>{{ $t("no_data") }}</i>
              </template>
            </template>
            <!-- end usage -->
          </SearchableTable>
        </div>
      </div>
    </div>
    <!-- END STATS -->

    <Modal
      :open.sync="modalOpen"
      :title="modalTitle"
      :allowOverflow="true"
      :footer="false"
      :canClose="true"
      :centerTitle="true"
    >
      <template #content>
        <div class="text-center">
          <div class="row">
            <div class="col-md-12 text-center">
              <i
                >({{ $t("updated_at") }}
                {{ dateTimeFormat(dataDetail.updated_at) }})</i
              >
            </div>
          </div>
          <div class="row" style="margin-bottom: 20px">
            <div class="col-md-12 text-center">
              <span>
                <strong>{{ $tc("enabled", 1) }}:</strong>&nbsp;
                <template v-if="dataDetail.enabled">
                  <span class="text-green">
                    <i class="fa fa-check-circle"></i>
                    {{ $t("yes") }}
                  </span>
                </template>
                <template v-else>
                  <span class="text-red">
                    <i class="fa fa-times-circle"></i>
                    {{ $t("no") }}
                  </span>
                </template>
              </span>
              |
              <span>
                <strong>{{ $t("collecting_data") }}:</strong>&nbsp;
                <template v-if="!dataDetail.is_blocked">
                  <span class="text-green">
                    <i class="fa fa-check-circle"></i>
                    {{ $t("yes") }}
                  </span>
                </template>
                <template v-else>
                  <span class="text-red">
                    <i class="fa fa-times-circle"></i>
                    {{ $t("no") }}
                  </span>
                  <i
                    >({{ $t("since") }}
                    {{ dateTimeFormat(dataDetail.blocked_at) }})</i
                  >
                </template>
              </span>
            </div>
          </div>
          <div class="row">
            <template v-for="(limit, level) in dataDetailLimits">
              <div
                :class="dataDetailLimitsClass"
                :key="level"
                v-if="dataDetail[limitReachedKey(level + 1)]"
              >
                <div class="alert alert-warning">
                  <h4>
                    <i class="icon fa fa-warning"></i>
                    {{ $t("limit_n_reached", { count: level + 1 }) }}
                  </h4>

                  <template v-if="limits[level] == 0">
                    {{ $t("limit_reached_message") }}
                  </template>
                  <template v-else>
                    {{
                      $t("limit_n_reached_message", { percent: limits[level] })
                    }}
                  </template>
                </div>
              </div>
            </template>
          </div>

          <div class="row">
            <div class="col-md-12">
              <strong>{{ $t("daily_consumption") }}</strong>
            </div>
          </div>
          <div class="row" v-if="dataDetail.day_2_stats">
            <div class="col-md-4">
              <strong
                >{{ dateFormat(dataDetail.day_2_stats.updated_at) }} -
                {{ weekDay(dataDetail.day_2_stats.updated_at) }}</strong
              >
            </div>
            <div class="col-md-8">
              <div class="clearfix">
                <small class="pull-left"
                  >{{
                    floatFormat(dailyUsage(dataDetail.day_2_stats.counter))
                  }}%</small
                >
                <small class="pull-right">
                  <strong>{{
                    floatFormat(dataDetail.day_2_stats.counter)
                  }}</strong>
                  / {{ floatFormat(dataDailyMaximum) }}
                </small>
              </div>
              <div class="progress xs lesser-margin-bottom">
                <div
                  class="progress-bar"
                  :class="
                    progressBarClass(dataDetail.day_2_stats.counter, 'day')
                  "
                  :style="
                    progressBarStyle(dataDetail.day_2_stats.counter, 'day')
                  "
                ></div>
              </div>
            </div>
          </div>
          <div class="row" v-if="dataDetail.day_1_stats">
            <div class="col-md-4">
              <strong
                >{{ dateFormat(dataDetail.day_1_stats.updated_at) }} -
                {{ weekDay(dataDetail.day_1_stats.updated_at) }}</strong
              >
            </div>
            <div class="col-md-8">
              <div class="clearfix">
                <small class="pull-left"
                  >{{
                    floatFormat(dailyUsage(dataDetail.day_1_stats.counter))
                  }}%</small
                >
                <small class="pull-right">
                  <strong>{{
                    floatFormat(dataDetail.day_1_stats.counter)
                  }}</strong>
                  / {{ floatFormat(dataDailyMaximum) }}
                </small>
              </div>
              <div class="progress xs lesser-margin-bottom">
                <div
                  class="progress-bar"
                  :class="
                    progressBarClass(dataDetail.day_1_stats.counter, 'day')
                  "
                  :style="
                    progressBarStyle(dataDetail.day_1_stats.counter, 'day')
                  "
                ></div>
              </div>
            </div>
          </div>
          <div class="row" v-if="dataDetail.day_0_stats">
            <div class="col-md-4">
              <strong
                >{{ dateFormat(dataDetail.day_0_stats.updated_at) }} -
                {{ weekDay(dataDetail.day_0_stats.updated_at) }}</strong
              >
            </div>
            <div class="col-md-8">
              <div class="clearfix">
                <small class="pull-left"
                  >{{
                    floatFormat(dailyUsage(dataDetail.day_0_stats.counter))
                  }}%</small
                >
                <small class="pull-right">
                  <strong>{{
                    floatFormat(dataDetail.day_0_stats.counter)
                  }}</strong>
                  / {{ floatFormat(dataDailyMaximum) }}
                </small>
              </div>
              <div class="progress xs lesser-margin-bottom">
                <div
                  class="progress-bar"
                  :class="
                    progressBarClass(dataDetail.day_0_stats.counter, 'day')
                  "
                  :style="
                    progressBarStyle(dataDetail.day_0_stats.counter, 'day')
                  "
                ></div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <strong>{{ $t("monthly_consumption") }}</strong>
            </div>
          </div>

          <div
            class="row text-left"
            v-if="
              dataDetail &&
              dataDetail.year_month_values &&
              dataDetail.year_month_values[currentYearMonth]
            "
          >
            <div class="col-md-12">
              <div
                class="info-box"
                :class="
                  infoBoxClass(
                    dataDetail.year_month_values[currentYearMonth],
                    'month',
                    currentYearMonth
                  )
                "
              >
                <span class="info-box-icon"
                  ><i class="fa fa-calendar"></i
                ></span>
                <div class="info-box-content">
                  <span class="info-box-text">{{
                    yearMonthName(currentYearMonth)
                  }}</span>
                  <span class="info-box-number"
                    >{{
                      floatFormat(
                        monthlyUsage(
                          dataDetail.year_month_values[currentYearMonth],
                          currentYearMonth
                        )
                      )
                    }}%</span
                  >
                  <div class="progress">
                    <div
                      class="progress-bar"
                      :style="
                        progressBarStyle(
                          dataDetail.year_month_values[currentYearMonth],
                          'month',
                          currentYearMonth
                        )
                      "
                    ></div>
                  </div>
                  <span class="progress-description">
                    <strong
                      >{{
                        floatFormat(
                          dataDetail.year_month_values[currentYearMonth]
                        )
                      }}
                      {{ $tc("record", 2) }}</strong
                    >
                    /
                    {{ floatFormat(dataMonthlyMaximum(currentYearMonth)) }}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12 text-center text-red">
              <i class="fa fa-info-circle"></i>
              {{ $t("limit_warning") }}
            </div>
          </div>

          <div
            class="row"
            v-for="(year_months, ix) in chunkYearMonths"
            :key="ix"
          >
            <template v-for="(year_month, subix) in year_months">
              <div class="col-md-2" :key="ix * 100 + subix * 10 + 1">
                <strong>{{ yearMonthName(year_month) }}</strong>
              </div>
              <div class="col-md-2" :key="ix * 100 + subix * 10 + 2">
                <div class="clearfix">
                  <small class="pull-left"
                    >{{
                      floatFormat(
                        monthlyUsage(
                          dataDetail.year_month_values[year_month],
                          year_month
                        )
                      )
                    }}%</small
                  >
                  <small class="pull-right">
                    <strong>{{
                      floatFormat(dataDetail.year_month_values[year_month])
                    }}</strong>
                    / {{ floatFormat(dataMonthlyMaximum(year_month)) }}
                  </small>
                </div>
                <div class="progress xs lesser-margin-bottom">
                  <div
                    class="progress-bar"
                    :class="
                      progressBarClass(
                        dataDetail.year_month_values[year_month],
                        'month',
                        year_month
                      )
                    "
                    :style="
                      progressBarStyle(
                        dataDetail.year_month_values[year_month],
                        'month',
                        year_month
                      )
                    "
                  ></div>
                </div>
              </div>
            </template>
          </div>
        </div>
      </template>
    </Modal>
  </section>
</template>

<script>
import DataService from "@/services/data.js";
import SearchableTable from "@/components/searchable-table.vue";
import Modal from "@/components/widgets/modal.vue";
import Spin from "@/components/spin";
import { contractPlanConst } from "@/assets/constants.js";

export default {
  name: "ConnectorHistoryUsageStatistics",
  props: {
    connector_id: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      data_history_usage_stats: [],
      dataDetail: {},
      modalOpen: false,
      limitLevels: [1, 2, 3],
      busy: true
    }
  },
  components: {
    SearchableTable,
    Modal,
    Spin
  },
  computed: {
    contract() {
      return this.$store.getters["user/contract"] || null;
    },
    isFreePlan() {
      return false || this?.contract?.contract_plan?.id == contractPlanConst.FREE;
    },
    contractHistoryRate() {
      let rate = this?.contract?.data_history_rate ?? 0;

      if (rate < 60) {
        return `${rate} ${this.$tc("second", rate)}`;
      } else {
        return `${rate / 60} ${this.$tc("minute", rate / 60)}`;
      }
    },
    limits() {
      return (this.contract?.data_history_monthly_overflow_limits || [0, 0, 0])
    },
    dataDailyMaximum() {
      return 86400 / (this?.contract?.data_history_rate ?? 0);
    },
    pageSize() {
      return parseInt(
        (this.$root.config &&
          this.$root.config.equipment_selection &&
          this.$root.config.equipment_selection.page_size) ||
        0 // no pagination
      );
    },
    items() {
      return (this?.data_history_usage_stats || []);
    },
    fields() {
      let fields = [
        {
          name: "name",
          title: this.$tc("data", 1)
        },
        {
          name: "device_name",
          title: this.$tc("device", 1)
        },
        {
          name: "collecting",
          title: this.$t("collecting_data")
        },
        {
          name: "day_3_consumption",
          title: this.$t("day_3_consumption"),
          parser: (item) => {
            if (!item?.day_2_stats || item?.day_2_stats?.counter == undefined) return '-';
            try {
              return this.floatFormat(item?.day_2_stats?.counter) ?? '-';
            } catch {
              return '-';
            }
          },
          hint: (item) => {
            if (!item?.day_2_stats || item?.day_2_stats?.counter == undefined) return this.$t("no_data");
            return this.$t("updated_at") + ': ' + this.dateTimeFormat(item.day_2_stats.updated_at) ?? this.$t("no_data");
          }
        },
        {
          name: "day_2_consumption",
          title: this.$t("day_2_consumption"),
          parser: (item) => {
            if (!item?.day_1_stats || item?.day_1_stats?.counter == undefined) return '-';
            try {
              return this.floatFormat(item?.day_1_stats?.counter) ?? '-';
            } catch {
              return '-';
            }
          },
          hint: (item) => {
            if (!item?.day_1_stats || item?.day_1_stats?.counter == undefined) return this.$t("no_data");
            return this.$t("updated_at") + ': ' + this.dateTimeFormat(item.day_1_stats.updated_at) ?? this.$t("no_data");
          }
        },
        {
          name: "day_1_consumption",
          title: this.$t("day_1_consumption"),
          parser: (item) => {
            if (!item?.day_0_stats || item?.day_0_stats?.counter == undefined) return '-';
            try {
              return this.floatFormat(item?.day_0_stats?.counter) ?? '-';
            } catch {
              return '-';
            }
          },
          hint: (item) => {
            if (!item?.day_0_stats || item?.day_0_stats?.counter == undefined) return this.$t("no_data");
            return this.$t("updated_at") + ': ' + this.dateTimeFormat(item.day_0_stats.updated_at) ?? this.$t("no_data");
          }
        },
        {
          name: "current_month_read",
          title: this.$t("current_month_read"),
          parser: (item) => {
            if (!item.hasOwnProperty('year_month_values') || !item?.year_month_values[this.currentYearMonth]) return '-';
            try {
              return (this.floatFormat(item?.year_month_values[this.currentYearMonth]) ?? '-');
            } catch {
              return '-';
            }
          },
          hint: (item) => {
            if (!item.hasOwnProperty('year_month_values') || !item?.year_month_values[this.currentYearMonth]) return this.$t("no_data");
            return this.$t("updated_at") + ': ' + this.dateTimeFormat(item.updated_at) ?? this.$t("no_data");
          }
        },
        {
          name: "current_month_usage",
          title: this.$t("current_month_usage")
        },
      ];
      return fields;
    },
    chunkYearMonths() {
      if (!this?.dataDetail || !this?.dataDetail?.year_month_keys) return [];
      return this.chunks(this.dataDetail.year_month_keys.slice(0, -1), 3);
    },
    commands() {
      return [
        {
          name: "edit",
          title: "edit",
          icon: "fa fa-pencil"
        },
        {
          name: "details",
          title: "see_details",
          icon: "fa fa-search"
        }
      ]
    },
    currentYearMonth() {
      let date = moment();
      let month = date.month() + 1;
      return `${date.year()}/${month >= 10 ? month : '0' + month}`
    },
    blockedData() {
      return this.data_history_usage_stats.filter((i) => i.is_blocked == true);
    },
    modalTitle() {
      return `${this.$t("history_monthly_consumption_allowed_connector")}: ${this.dataDetail?.name}`;
    },
    dataDetailLimits() {
      if (!this.dataDetail) return [];
      let limits = [];
      if (this.dataDetail.limit_reached_1) limits.push(this.dataDetail.limit_reached_1);
      if (this.dataDetail.limit_reached_2) limits.push(this.dataDetail.limit_reached_2);
      if (this.dataDetail.limit_reached_3) limits.push(this.dataDetail.limit_reached_3);
      return limits;
    },
    dataDetailLimitsClass() {
      if (!this.dataDetail) return 'col-md-12';
      if (this.dataDetail?.limit_reached_3 && this.dataDetail?.limit_reached_2 && this.dataDetail?.limit_reached_1) return 'col-md-4';
      if (this.dataDetail?.limit_reached_2 && this.dataDetail?.limit_reached_1) return 'col-md-6';
      if (this.dataDetail?.limit_reached_1) return 'col-md-12';
      return 'col-md-12';
    }
  },
  methods: {
    haveHistory(data) {
      let have = false;
      for (let i = 0; i <= 2; i++) {
        if (data.hasOwnProperty(`day_${i}_stats`) && data[`day_${i}_stats`]) {
          have = true;
          break;
        }
      }
      if (!have) {
        for (let i = 1; i <= 12; i++) {
          if (data.hasOwnProperty(`month_${i}_stats`) && data[`month_${i}_stats`]) {
            have = true;
            break;
          }
        }
      }
      return have;
    },
    hintBlockedAt(datetime) {
      return this.$t("limit_n_reached_at", { date: this.dateTimeFormat(datetime) });
    },
    hintLimitReached(percent) {
      return this.$t("limit_n_reached_message", { percent });
    },
    hintDayConsumption(item, day_x) {
      if (!item.hasOwnProperty(`day_${day_x}_stats`)) return this.$t("no_data");
      let stats = item[`day_${day_x}_stats`];
      if (!stats.counter && stats.counter == undefined) return this.$t("no_data");
      return this.$t("updated_at") + ': ' + this.dateTimeFormat(stats.updated_at) ?? this.$t("no_data");
    },
    dateFormat(str_date) {
      return moment(str_date).format('DD/MM/YYYY');
    },
    dateTimeFormat(str_date) {
      return moment((str_date || "").replace(/\.\d.+$/, '.000Z')).format('DD/MM/YYYY HH:mm:ss');
    },
    weekDay(str_date) {
      return moment(str_date).format('dddd');
    },
    totalDaysMonth(year_month) {
      if (!year_month) return 30;
      let parts = (year_month + '').split('/');
      let str_date = `${parts[0]}-${parts[1]}-01`;
      return parseInt(moment(str_date).endOf('month').format('DD'));
    },
    floatFormat(value) {
      if (value == null) return '';
      return value.toLocaleString(this.$i18n.locale);
    },
    dailyUsage(usage) {
      try {
        let result = usage * 100 / this.dataDailyMaximum;
        return result < 1 ? Math.round(result * 100) / 100 : Math.round(result);
      } catch {
        return 0;
      }
    },
    dataMonthlyMaximum(year_month) {
      return this.dataDailyMaximum * this.totalDaysMonth(year_month);
    },
    monthlyUsage(usage, year_month) {
      try {
        let result = usage * 100 / this.dataMonthlyMaximum(year_month);
        return result < 1 ? Math.round(result * 100) / 100 : Math.round(result);
      } catch {
        return 0;
      }
    },
    infoBoxClass(usage, period, year_month) {
      let calc = period == 'day' ? this.dailyUsage(usage) : this.monthlyUsage(usage, year_month);
      if (calc >= (100 + this.limits[2])) return "bg-red";
      if (calc >= (100 + this.limits[1])) return "bg-orange";
      if (calc >= (100 + this.limits[0])) return "bg-yellow";
      return "bg-green";
    },
    progressBarClass(usage, period, year_month) {
      let calc = period == 'day' ? this.dailyUsage(usage) : this.monthlyUsage(usage, year_month);
      if (calc >= (100 + this.limits[2])) return "progress-bar-red";
      if (calc >= (100 + this.limits[1])) return "progress-bar-orange";
      if (calc >= (100 + this.limits[0])) return "progress-bar-yellow";
      return "progress-bar-green";
    },
    progressBarStyle(usage, period, year_month) {
      let calc = period == 'day' ? this.dailyUsage(usage) : this.monthlyUsage(usage, year_month);
      return {
        width: (calc <= 100 ? calc : 100) + '%'
      };
    },
    yearMonthName(year_month) {
      let parts = year_month.split("/");
      let month = parseInt(parts[1]);
      let monthNames = [
        '',
        this.$t("calendar.January"),
        this.$t("calendar.February"),
        this.$t("calendar.March"),
        this.$t("calendar.April"),
        this.$t("calendar.May"),
        this.$t("calendar.June"),
        this.$t("calendar.July"),
        this.$t("calendar.August"),
        this.$t("calendar.September"),
        this.$t("calendar.October"),
        this.$t("calendar.November"),
        this.$t("calendar.December")
      ];

      return `${monthNames[month]} / ${parts[0]}`;
    },
    chunks(arr, size) {
      if (!arr) return [];
      const result = []
      for (let i = 0; i < arr.length; i += size)
        result.push(arr.slice(i, i + size));
      return result
    },
    limitReachedKey(level) {
      return `limit_reached_${level}`;
    },
    showModalDetails(data) {
      if (this.haveHistory(data)) {
        this.modalOpen = true;
        this.dataDetail = data;
      } else {
        this.$swal({
          title: this.$t("no_data"),
          text: this.$t("there_are_no_data_to_show"),
          icon: "info"
        });
      }
    },
    onCommand(command) {
      if (command.name == 'edit') {
        this.busy = true;
        this.getData(command.target.id).then((data) => {
          if (!data) return;
          this.$router.push(`/dashboard/edit/connector/${data?.device?.connector?.id}/device/${data?.device?.id}/data/${data?.id}`);
        });
      } else if (command.name == 'details') {
        this.showModalDetails(command.target);
      }
    },
    onSelect(item) {
      this.showModalDetails(item);
    },
    getData(data_id) {
      return new Promise((resolve, reject) => {
        let data = (this.$store.getters["dashboard/dataList"] || []).find(
          ({ id }) => parseInt(id) == parseInt(data_id)
        );
        if (data) {
          resolve(data);
        } else {
          this.service.get(data_id, this.contract.id).then(
            (result) => {
              if (result) {
                resolve(result);
              } else {
                reject(null);
              }
            }
          );
        }
      });
    }
  },
  mounted() {
    let query = {
      connector_id: this.connector_id,
      only_history_statistics: true,
      history_enabled: true
    }

    this.service.list(query, this.contract.id).then(
      (result) => {
        this.data_history_usage_stats = (result || []).map(
          (item) => {
            let stats = item.data_history_usage_stats ?? {};
            delete item.data_history_usage_stats;
            let year_month_values = {};
            let year_month_keys = [];
            let key = '';
            for (let month = 1; month <= 12; month++) {
              if (stats && stats.hasOwnProperty(`month_${month}_stats`) && stats[`month_${month}_stats`]) {
                key = `${stats[`month_${month}_stats`].year}/${month >= 10 ? month : '0' + month}`;
                year_month_values[key] = stats[`month_${month}_stats`].counter;
                year_month_keys.push(key);
              }
            }

            stats.year_month_values = year_month_values;
            stats.year_month_keys = year_month_keys.sort();

            return {
              ...item,
              ...stats
            };
          }
        ).sort(
          (a, b) => (b?.year_month_values[this.currentYearMonth] ?? 0) - (a?.year_month_values[this.currentYearMonth] ?? 0)
        )

        this.busy = false;
      }
    )
  },
  created() {
    this.busy = true;
    this.service = new DataService();
  }
}
</script>

<style scoped>
.box-total-text {
  font-size: 18pt !important;
  font-weight: bold;
}

.box-title-min-height {
  min-height: 50px;
}

.box-body-min-height {
  text-align: center;
  min-height: 80px;
}

.progress-bar-orange {
  background-color: #f0530a;
}

.bg-orange {
  background-color: #f0530a;
}

.lesser-margin-bottom {
  margin-bottom: 5px;
}

@media (max-width: 1439px) {
  .box-title-min-height {
    min-height: 60px;
  }

  .box-body-min-height {
    min-height: 130px;
  }
}
@media (max-width: 1365px) {
  .box-title-min-height {
    min-height: 60px;
  }

  .box-body-min-height {
    min-height: 80px;
  }
}
</style>