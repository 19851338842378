<template>
  <div class="nav-tabs-custom">
    <ul class="nav nav-tabs pull-right ui-sortable-handle">
      <template v-if="tabs.length">
        <li
          v-for="(item, ix) in tabs"
          :key="ix"
          :title="$tc(item.title)"
          :class="{
            active: isCurrent(item),
            'btn-group': item.options && item.options.length,
            'form-tab': item.formTab
          }"
        >
          <template v-if="item.options && item.options.length">
            <a
              aria-expanded="true"
              data-toggle="tab"
              :href="`#${item.id}`"
              ref="tab"
              role="tab"
              @click="activate(`#${item.id}`)"
            >
              <Icon class="icon" :name="item.icon" />
              <template v-if="nav.showTabLabels && item.label">
                {{ $tc(item.label, 1).toUpperCase() }}
              </template>
            </a>
            <span
              type="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="true"
              class="btn dropdown-toggle pull-right"
            >
              <span class="caret"></span>
              <span class="sr-only">Toggle Dropdown</span>
            </span>
            <ul class="dropdown-menu">
              <li
                v-for="option in item.options"
                :key="option.title"
                :class="{active: tab == `#${option.id}`}"
                @click.stop="activate(`#${option.id}`)"
              >
                <a
                  data-toggle="tab"
                  href="javascript:void(0)"
                  :data-target="`#${option.id}`"
                  ref="tab"
                  role="tab"
                  >{{ $tc(option.title, 1) }}</a
                >
              </li>
            </ul>
          </template>
          <template v-else>
            <span
              v-if="badge && item.badge && item.badge.text"
              data-toggle="tooltip"
              v-bind:title="item.badge.title || ''"
              class="badge badge-tab"
              v-bind:class="item.badge.class || ''"
            >
              <Icon
                v-if="item.badge.icon"
                class="icon"
                :name="item.badge.icon"
              />
              <span v-else>{{ item.badge.text }}</span>
            </span>
            <a
              aria-expanded="true"
              data-toggle="tab"
              :href="`#${item.id}`"
              ref="tab"
              role="tab"
              @click="activate(`#${item.id}`)"
            >
              <Icon class="icon" :name="item.icon" />
              <template v-if="nav.showTabLabels && item.label">
                {{ $tc(item.label, 1).toUpperCase() }}
              </template>
            </a>
          </template>
        </li>
      </template>
      <li
        class="header"
        :style="{
          'margin-right': tabs.length ? '20px' : '0',
          'margin-bottom:': '6px'
        }"
        v-if="connector_id"
      >
        <div
          class="btn btn-default"
          @click.stop.prevent="onDashboardClick"
          :title="`${connectorName} Dashboard`"
        >
          <i class="fa fa-dashboard"></i>
          {{ $t("dashboard") }}
        </div>
      </li>
      <li class="pull-left header">
        <span class="title">
          <Icon v-if="icon" class="icon" :name="icon" />
          {{ $te(title) ? $tc(title, 2) : title }}
        </span>
        <template v-if="nItems">
          <span class="badge badge-info" v-if="nFiltered == nAvailable">{{
            nAvailable
          }}</span>
          <span class="badge badge-info" v-else
            >{{ nFiltered }} / {{ nAvailable }}</span
          >
        </template>
      </li>
    </ul>
    <Breadcrumb v-bind:nav="extendedNav" />
  </div>
</template>

<script>
import {isEqual} from "lodash";
import Breadcrumb from "@/components/breadcrumb.vue";
import Icon from "@/components/icons/icon.vue";
export default {
  name: "PanelHeaderEquipmentList",
  components: {
    Breadcrumb,
    Icon
  },
  props: {
    nItems: {
      type: Object,
      default: null,
      required: false
    },
    title: {
      type: String,
      default: "untitled",
      required: false
    },
    icon: {
      type: String,
      default: "fa fa-inbox",
      required: false
    },
    nav: {
      type: Object,
      required: false,
      default: () => null
    },
    connector_id: {
      type: [Number, String],
      default: 0,
      required: false
    }
  },
  data() {
    return {
      showTabs: false,
      firstTime: true,
      badge: false
    };
  },
  computed: {
    tab() {
      return this.$route.hash || "";
    },
    tabs() {
      return (this.nav && this.nav?.tabs?.items) || [];
    },
    nAvailable() {
      return (this.nItems && this.nItems.available) || 0;
    },
    nFiltered() {
      return (this.nItems && this.nItems.filtered) || 0;
    },
    connectorName() {
      let connector = this.$store.getters["equipmentList"]?.find(
        (i) => i.id == this.connector_id
      );
      return (connector && connector.name) || "";
    },
    extendedNav() {
      let self = this;
      let nav = JSON.parse(JSON.stringify(this.nav));
      if (this.connector_id) {
        (nav?.items || []).forEach((item, ix) => {
          if (ix == 1) {
            item.button = {
              icon: "fa fa-dashboard",
              title: `${self.connectorName} Dashboard`,
              url: `/dashboard/equipment/${this.connector_id}`
            };
          }
        });
      }
      return nav;
    }
  },
  watch: {
    nav(n, o) {
      if (n && !isEqual(n, o)) {
        let hash = (this.$route.hash || "#edit").split("?")[0];
        if (!o || this.tab != hash) {
          this.activate(hash);
        }
        setTimeout(() => {
          this.badge = true;
        }, 2000);
      }
    },
    tabs(n, o) {
      if (!isEqual(n, o)) {
        setTimeout(() => {
          this.badge = true;
        }, 2000);
      }
    },
    $route(to) {
      if (to.hash) {
        this.$emit("tab", to.hash);
      }
    }
  },
  methods: {
    isCurrent(item) {
      if (this.tab == `#${item.id}`) return true;
      return (
        (item?.options || []).some(({id}) => this.tab == `#${id}`) || false
      );
    },
    onDashboardClick() {
      this.$router.push(`/dashboard/equipment/${this.connector_id}`);
    },
    activate(hash) {
      let dropDown = this.$el.getElementsByClassName("btn-group");
      if (dropDown && dropDown.length) {
        dropDown[0].classList.remove("open");
      }
      this.$emit("tab", hash);
      if (hash != this.$route.hash) {
        let href = document.location.href;
        let iqs = href.indexOf("?");
        let qs = iqs > 0 ? document.location.href.substr(iqs) : "";
        this.$router.replace({hash: hash}).then(() => {
          if (qs && document.location.href.indexOf("?") == -1) {
            window.history.replaceState(
              null,
              "",
              `${document.location.href}${qs}`
            );
          }
        });
      }
    }
  }
};
</script>
<style scoped>
.badge-info {
  margin: -15px 0 0 2px;
  background-color: lightgray;
}
ul.nav {
  min-height: 42px;
}

li > span.badge-tab {
  position: absolute;
  right: 4px;
  z-index: 1;
}

.clicable:hover {
  cursor: pointer;
  opacity: 0.8;
  color: #f39c12;
}

.nav > li > a {
  padding: 10px;
}

.nav > li:hover > a {
  cursor: pointer;
}
.nav .open > a {
  border-color: none;
}
span.title {
  font-size: 16px;
}

.badge-info {
  padding: 1px 3px;
  background-color: #607d8b;
  margin: -22px 0 0 -9px;
}

.dropdown-menu > li.active > a {
  /* color: inherit;
  text-decoration: inherit;
  background-color: inherit;
  outline: inherit; */
}

.nav-tabs-custom > .nav-tabs > li.btn-group > a {
  display: inline-block;
  padding-right: 0;
}

.nav-tabs-custom > .nav-tabs > li.active.btn-group > a,
.nav-tabs-custom > .nav-tabs > li.active.btn-group:hover > a {
  border-right-color: transparent;
}

li.form-tab {
  margin-right: 0;
}

li.form-tab > a {
  display: none;
}

@media (min-width: 720px) {
  .nav > li > a {
    padding: 10px 15px;
  }

  span.title {
    font-size: 20px;
  }

  .badge-info {
    padding: 3px 7px;
    background-color: #b3b3b3;
    margin: -15px 0 0 2px;
  }
}

.nav-tabs-custom {
  margin-bottom: 0;
}

svg.icon {
  vertical-align: -2px;
}
</style>
