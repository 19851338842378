var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"nav-tabs-custom"},[_c('ul',{staticClass:"nav nav-tabs pull-right ui-sortable-handle"},[(_vm.tabs.length)?_vm._l((_vm.tabs),function(item,ix){return _c('li',{key:ix,class:{
          active: _vm.isCurrent(item),
          'btn-group': item.options && item.options.length,
          'form-tab': item.formTab
        },attrs:{"title":_vm.$tc(item.title)}},[(item.options && item.options.length)?[_c('a',{ref:"tab",refInFor:true,attrs:{"aria-expanded":"true","data-toggle":"tab","href":`#${item.id}`,"role":"tab"},on:{"click":function($event){return _vm.activate(`#${item.id}`)}}},[_c('Icon',{staticClass:"icon",attrs:{"name":item.icon}}),(_vm.nav.showTabLabels && item.label)?[_vm._v(" "+_vm._s(_vm.$tc(item.label, 1).toUpperCase())+" ")]:_vm._e()],2),_vm._m(0,true),_c('ul',{staticClass:"dropdown-menu"},_vm._l((item.options),function(option){return _c('li',{key:option.title,class:{active: _vm.tab == `#${option.id}`},on:{"click":function($event){$event.stopPropagation();return _vm.activate(`#${option.id}`)}}},[_c('a',{ref:"tab",refInFor:true,attrs:{"data-toggle":"tab","href":"javascript:void(0)","data-target":`#${option.id}`,"role":"tab"}},[_vm._v(_vm._s(_vm.$tc(option.title, 1)))])])}),0)]:[(_vm.badge && item.badge && item.badge.text)?_c('span',{staticClass:"badge badge-tab",class:item.badge.class || '',attrs:{"data-toggle":"tooltip","title":item.badge.title || ''}},[(item.badge.icon)?_c('Icon',{staticClass:"icon",attrs:{"name":item.badge.icon}}):_c('span',[_vm._v(_vm._s(item.badge.text))])],1):_vm._e(),_c('a',{ref:"tab",refInFor:true,attrs:{"aria-expanded":"true","data-toggle":"tab","href":`#${item.id}`,"role":"tab"},on:{"click":function($event){return _vm.activate(`#${item.id}`)}}},[_c('Icon',{staticClass:"icon",attrs:{"name":item.icon}}),(_vm.nav.showTabLabels && item.label)?[_vm._v(" "+_vm._s(_vm.$tc(item.label, 1).toUpperCase())+" ")]:_vm._e()],2)]],2)}):_vm._e(),(_vm.connector_id)?_c('li',{staticClass:"header",style:({
        'margin-right': _vm.tabs.length ? '20px' : '0',
        'margin-bottom:': '6px'
      })},[_c('div',{staticClass:"btn btn-default",attrs:{"title":`${_vm.connectorName} Dashboard`},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.onDashboardClick.apply(null, arguments)}}},[_c('i',{staticClass:"fa fa-dashboard"}),_vm._v(" "+_vm._s(_vm.$t("dashboard"))+" ")])]):_vm._e(),_c('li',{staticClass:"pull-left header"},[_c('span',{staticClass:"title"},[(_vm.icon)?_c('Icon',{staticClass:"icon",attrs:{"name":_vm.icon}}):_vm._e(),_vm._v(" "+_vm._s(_vm.$te(_vm.title) ? _vm.$tc(_vm.title, 2) : _vm.title)+" ")],1),(_vm.nItems)?[(_vm.nFiltered == _vm.nAvailable)?_c('span',{staticClass:"badge badge-info"},[_vm._v(_vm._s(_vm.nAvailable))]):_c('span',{staticClass:"badge badge-info"},[_vm._v(_vm._s(_vm.nFiltered)+" / "+_vm._s(_vm.nAvailable))])]:_vm._e()],2)],2),_c('Breadcrumb',{attrs:{"nav":_vm.extendedNav}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"btn dropdown-toggle pull-right",attrs:{"type":"button","data-toggle":"dropdown","aria-haspopup":"true","aria-expanded":"true"}},[_c('span',{staticClass:"caret"}),_c('span',{staticClass:"sr-only"},[_vm._v("Toggle Dropdown")])])
}]

export { render, staticRenderFns }